<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.orderInfo')" />

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <!-- Status -->
          <v-row v-if="isShowStatus">
            <v-col
              cols="12"
              lg="4"
              md="4"
              sm="4"
              align-self="center"
              class="pa-0"
            >
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.status") }}
              </span>
            </v-col>

            <v-spacer />

            <v-col
              cols="12"
              lg="3"
              md="3"
              sm="3"
              class="pa-0 d-flex justify-end alig-center"
            >
              <v-btn
                color="green darken-2"
                outlined
                @click="handleDialogModifyStatus"
              >
                {{ $vuetify.lang.t("$vuetify.modifyStatus") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-timeline align-top dense class="my-6" v-if="isShowStatus">
            <v-timeline-item
              color="green"
              small
              v-for="(item, key) in myObj.status_opt"
              :key="key"
            >
              <div>
                <div class="font-weight-normal">
                  <strong v-if="item.status == 1">
                    {{ $vuetify.lang.t("$vuetify.placeOrder") }}
                  </strong>

                  <strong v-if="item.status == -1">
                    {{ $vuetify.lang.t("$vuetify.cancel") }}
                  </strong>

                  <strong v-if="item.status == 3">
                    {{ $vuetify.lang.t("$vuetify.paid") }}
                  </strong>

                  <strong v-if="item.status == 6">
                    {{ $vuetify.lang.t("$vuetify.shipped") }}
                  </strong>

                  <strong v-if="item.status == 7">
                    {{ $vuetify.lang.t("$vuetify.offStocks") }}
                  </strong>
                </div>

                <div v-if="item.user_id == 'sync'">
                  {{ $vuetify.lang.t("$vuetify.systemSync") }}
                </div>

                <div v-if="item.user_id != 'sync'">
                  {{ item.name }}
                  <span v-if="item.mobile != ''">({{ item.mobile }})</span>
                </div>
              </div>

              <div>
                {{ item.opt_time }}
              </div>
            </v-timeline-item>
          </v-timeline>

          <!-- cutomerInfo -->
          <v-row>
            <v-col cols="12" class="pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.cutomerInfo") }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-row>
                <v-col class="py-0" cols="10" lg="5" md="5" sm="11">
                  <v-text-field
                    style="color: black"
                    :label="$vuetify.lang.t('$vuetify.cutomerName')"
                    :rules="generalRule"
                    v-model="customer"
                    readonly
                  />
                </v-col>

                <span class="d-flex align-center justify-center">
                  <v-btn
                    elevation="0"
                    fab
                    small
                    color="primary"
                    @click="handleDialogCustomer"
                    :dark="addAction == true"
                    :disabled="addAction == false"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </span>

                <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />

                <!-- Shipping Address -->
                <v-col class="py-0" cols="10" lg="5" md="5" sm="11">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.shouhuoAddress')"
                    :rules="generalRule"
                    v-model="shippingAddr"
                    readonly
                  />
                </v-col>

                <span class="d-flex align-center justify-center">
                  <v-btn
                    elevation="0"
                    color="primary"
                    fab
                    small
                    @click="handleDialogShipping"
                    dark
                  >
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                </span>

                <v-col class="py-0" cols="12" lg="6" md="12" sm="12">
                  <v-menu
                    :close-on-content-click="true"
                    transition="scale-transition"
                    min-width="auto"
                    class="ma-0 pa-0"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                      class="ma-0 pa-0"
                    >
                      <v-text-field
                        append-icon="mdi-calendar"
                        v-model="myObj.order_date"
                        :label="$vuetify.lang.t('$vuetify.deliveryDate')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="generalRule"
                      />
                    </template>

                    <v-date-picker
                      v-model="myObj.order_date"
                      @input="date = false"
                      class="ma-0 pa-0"
                    />
                  </v-menu>
                </v-col>

                <!-- Courier -->
                <v-col class="py-0" cols="12" lg="6" md="12" sm="12">
                  <v-radio-group
                    v-model="myObj.post_way"
                    row
                    :label="$vuetify.lang.t('$vuetify.kuaidifangshi')"
                  >
                    <v-radio
                      :value="1"
                      :label="$vuetify.lang.t('$vuetify.kuaidi')"
                    />

                    <v-radio
                      :value="2"
                      :label="$vuetify.lang.t('$vuetify.ziti')"
                    />

                    <v-radio
                      :value="3"
                      :label="$vuetify.lang.t('$vuetify.Shipping')"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>

              <!-- Payment Info -->
              <v-row>
                <v-col cols="12" class="pa-0">
                  <span class="display-2">
                    <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                    {{ $vuetify.lang.t("$vuetify.payMentInfo") }}
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <!-- Payment Method -->
                <v-col class="py-0" cols="12">
                  <v-radio-group
                    v-model="myObj.pay_way"
                    row
                    :label="$vuetify.lang.t('$vuetify.zhifufangshi')"
                  >
                    <v-radio
                      :value="1"
                      :label="$vuetify.lang.t('$vuetify.onlinePay')"
                    />

                    <v-radio
                      :value="2"
                      :label="$vuetify.lang.t('$vuetify.huodaofukuan')"
                    />

                    <v-radio
                      :value="3"
                      :label="$vuetify.lang.t('$vuetify.cash')"
                    />

                    <v-radio
                      :value="4"
                      :label="$vuetify.lang.t('$vuetify.creditCard')"
                    />

                    <v-radio
                      :value="5"
                      :label="$vuetify.lang.t('$vuetify.other')"
                    />
                  </v-radio-group>
                </v-col>

                <!-- Currency -->
                <v-col class="py-0" cols="12">
                  <v-select
                    v-model="myObj.currency"
                    :items="currencyOptions"
                    :label="$vuetify.lang.t('$vuetify.currency')"
                  />
                </v-col>
              </v-row>

              <!-- Extended Info -->
              <v-row class="py-2">
                <v-col cols="12" class="pa-0">
                  <span class="display-2">
                    <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                    {{ $vuetify.lang.t("$vuetify.extendedInfo") }}
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pb-0" cols="12" lg="6" md="6" sm="12">
                  <v-textarea
                    :label="$vuetify.lang.t('$vuetify.remarks')"
                    v-model="myObj.buyer_message"
                    outlined
                  />
                </v-col>

                <v-col class="pb-0" cols="12" lg="6" md="6" sm="12">
                  <v-textarea
                    :label="$vuetify.lang.t('$vuetify.complain')"
                    v-model="myObj.note"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-file-input
                    class="pa-0 ma-0 mt-0"
                    :label="$vuetify.lang.t('$vuetify.annex')"
                    prepend-inner-icon="mdi-file"
                    prepend-icon=""
                    @change="(item) => uploadFile(item)"
                  />
                </v-col>

                <!-- List Files -->
                <v-col cols="12" class="pt-0">
                  <span class="display-2 font-weight-bold">
                    List of Documents
                  </span>

                  <v-sheet
                    elevation="4"
                    class="my-4 py-4"
                    height="250px"
                    style="overflow-y: scroll"
                  >
                    <span
                      class="d-flex align-center justify-center"
                      v-for="(item, key) in attachments"
                      :key="key"
                    >
                      <v-list-item
                        class="tile ml-4 mr-2 my-2"
                        :href="dataUrl + item"
                        target="_blank"
                      >
                        {{ item }}
                      </v-list-item>

                      <v-btn
                        color="red"
                        class="ml-2 mr-4"
                        outlined
                        @click="removeAttach(item)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </span>
                  </v-sheet>
                </v-col>
              </v-row>

              <!-- Product List -->
              <v-row>
                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  sm="4"
                  align-self="center"
                  class="pa-0"
                >
                  <span class="display-2">
                    <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                    {{ $vuetify.lang.t("$vuetify.goodsList") }}
                  </span>
                </v-col>

                <v-spacer />

                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="3"
                  class="pa-0 d-flex justify-end alig-center"
                >
                  <v-btn color="primary" outlined @click="handleDialogProduct">
                    {{ $vuetify.lang.t("$vuetify.goodsAdd") }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row class="pt-4 pb-2">
                <v-col cols="12">
                  <ProductTable
                    :head="headers"
                    :data="selectedProduct"
                    :myObj="myObj"
                    v-on:deleteitem="handleDeleteDialog"
                    v-on:TotalPriceQty="TotalPriceQty"
                  />

                  <DialogDelete
                    :dialogDelete.sync="dialogDelete"
                    :deleteObj="deleteProductObj"
                    @handleDelete="handleDeleteConfirm"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="3"
                  class="font-weight-bold"
                  align-self="center"
                >
                  <v-btn color="primary" outlined @click="handleDialogDetail">
                    {{ $vuetify.lang.t("$vuetify.detail") }}
                  </v-btn>
                </v-col>

                <v-spacer />

                <v-col cols="12" lg="3" md="4" sm="5" align-self="center">
                  <v-row no-gutters>
                    <v-col cols="12" class="font-weight-bold text-right">
                      {{ $vuetify.lang.t("$vuetify.goodsTotal") }} :
                      <v-chip color="blue" dark> {{ totalQty }} </v-chip>
                    </v-col>

                    <v-col cols="12" class="mt-2 font-weight-bold text-right">
                      {{ $vuetify.lang.t("$vuetify.TotalAmount") }} :
                      <v-chip color="blue" dark> $ {{ totalPrice }} </v-chip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12" lg="4" md="4" sm="3">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.youfei')"
                    type="number"
                    v-model.number="myObj.post_price"
                  />
                </v-col>

                <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.taxRate') + ' (%)'"
                    type="number"
                    v-model.number="myObj.rate"
                  />
                </v-col>

                <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
                  <v-text-field
                    type="number"
                    :label="$vuetify.lang.t('$vuetify.Amount')"
                    v-model.number="totalPrice"
                    prefix="$"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />

      <v-btn color="red darken-2" outlined @click="cancelForm">
        {{ $vuetify.lang.t("$vuetify.cancel") }}
      </v-btn>

      <v-btn
        color="green darken-2"
        @click="validate"
        v-if="permissionCheck('modify', 'order')"
      >
        {{ $vuetify.lang.t("$vuetify.save") }}
      </v-btn>

      <v-btn
        color="red"
        @click="$emit('deleteOrder', myObj)"
        v-if="permissionCheck('del', 'order') && addAction == false"
      >
        {{ $vuetify.lang.t("$vuetify.delete") }}
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
import { orderDelete, orderInfo } from "@/api/order";
import { listCurrency } from "@/api/system";
import { customerList } from "@/api/basicdata";
import { profilePicUpload } from "@/api/company";
import serverConfig from "@/utils/serverConfig";
import { draggable } from "@/api/customFunction";

const clone = (clonedObj) => Object.assign({}, clonedObj);
const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];

  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

const newObj = () => {
  return {
    id: "",
    customer_id: "", // 客户id
    delivery_addr: "", // {contact:,tel,province,city,district,delivery_addr}
    products: "", // [{id:,nick_name:,number:price:}]
    number: 0, // 商品总数量
    trade_total: 0, // 商品总价格
    pay_way: 1, // 1 在线支付 2 货到付款
    post_way: 1, // 1快递 2自提 3骑手
    pay_path: "", // 账期或者支付方式
    post_price: 0, // 邮费
    rate: "", // 税率
    price: 0, // 订单实付金额  总价格加油费
    currency: "USD", // 币种
    attach: "", // 订单附件[{md5:note}]
    buyer_message: "", // 买家备注
    note: "", // 商家备注
    price_detail: "", // {cash:online:other:} 总价明细
    order_date: new Date().toISOString().substr(0, 10), // 订单时间
    status: "", // 状态 -1 取消 1下单 2待成团 3已支付 6已发货 7 已完成  修改时没有待成团选项
  };
};

const newDelete = () => {
  return {
    id: "",
    nick_name: "",
    number: "",
    price: "",
    image: "",
    totalPrice: "",
    code: "",
  };
};

export default {
  components: {
    DialogDelete: () => import("@/components/deleteDialog"),
    ProductTable: () => import("@/components/purchase/purchaseProductTable"),
    DialogTitle: () => import("@/components/dialogTitle"),
  },
  props: {
    dialog: Boolean,
    obj: Object,
    selectedProduct: Array,
    isDeleteOrder: Boolean,
    addAction: Boolean,
    shippingAddr: String,
    orderStatus: String,
  },
  data() {
    return {
      totalQty: 0,
      totalPrice: 0,
      customer: "",
      dataUrl: serverConfig.img_url,
      valid: false,
      dialogDelete: false,
      isShowStatus: false,
      myObj: newObj(),
      deleteProductObj: newDelete(),
      currencyOptions: [],
      attachments: [],
      generalRule: [(v) => !!v || "this field is required"],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.productPicture"),
          value: "image",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "productDescription",
          width: "500px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.number"),
          value: "number",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.price"),
          value: "price",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.totalPrice1"),
          value: "totalPrice",
          sortable: false,
          align: "center",
          width: "100px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "deleteProduct",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  methods: {
    drag() {
      draggable();
    },
    validate() {
      if (this.selectedProduct.length > 0) {
        this.myObj.products = this.selectedProduct;

        this.myObj.number = this.totalQty;
        this.myObj.price = this.totalPrice;
        this.myObj.trade_total = this.totalPrice;

        if (this.$refs.form.validate() == true) {
          this.myObj.attach = JSON.stringify(this.attachments);

          this.$emit("handleOrderData", this.myObj);

          this.attachments = [];
        } else {
          console.log("false");
        }
        this.customer = "";
      } else {
        this.valid = false;
        this.$toast.info(
          this.$vuetify.lang.t("$vuetify.pleaseAddProductFirst")
        );
      }
    },
    cancelForm() {
      this.resetValidation();
      this.$emit("update:dialog", false);
      this.$emit("update:addAction", false);
      this.customer = "";
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    handleDialogProduct() {
      this.$emit("selectProduct");
    },
    handleDialogDetail() {
      this.$emit("paymentDetail");
    },
    handleDialogShipping() {
      this.$emit("shippingAddress");
    },
    handleDialogCustomer() {
      this.$emit("selectCustomer");
    },
    handleDialogModifyStatus() {
      this.$emit("modifyStatus");
    },
    handleDeleteDialog(obj) {
      this.deleteProductObj = obj;
      this.deleteIndex = this.selectedProduct.indexOf(obj);
      this.dialogDelete = true;
    },
    handleDeleteConfirm() {
      if (this.deleteIndex > -1) {
        this.selectedProduct.splice(this.deleteIndex, 1);
        this.deleteIndex = -1;
      }
      this.dialogDelete = false;
      this.TotalPriceQty();
    },
    TotalPriceQty() {
      this.totalQty = 0;
      this.totalPrice = 0;

      this.selectedProduct.forEach((p) => {
        p.price == undefined ? (p.price = 0) : (p.price = p.price);
        p.number == undefined ? (p.number = 0) : (p.number = p.number);

        this.totalPrice += parseFloat(p.number) * parseFloat(p.price);

        this.totalQty += parseInt(p.number);
      });

      this.totalPrice = this.totalPrice.toFixed(2);
    },
    getOrderinfo() {
      if (this.myObj.id) {
        this.isShowStatus = true;
        orderInfo(this.myObj.id).then((res) => {
          this.myObj = res.item;
          this.customer = this.myObj.customer_id;

          this.$emit("handleAddressData", this.myObj.delivery_addr);
          this.$emit("handlePriceDetailData", this.myObj.price_detail);
        });
      }
    },
    getCurrency() {
      listCurrency().then((data) => {
        data.items.forEach((p) => {
          this.currencyOptions.push({ text: p.name, value: p.code });
        });
      });
    },
    getCustomer() {
      customerList(this.searchForm)
        .then((data) => {
          var datas = [...data.items];

          for (var i = 0; i < datas.length; i++) {
            if (datas[i].id == this.myObj.customer_id) {
              this.customer = datas[i].company;
            }
          }
        })
        .catch((err) => console.log(err));
    },
    uploadFile(file) {
      const fileForm = new FormData();
      fileForm.append("file", file);

      profilePicUpload(fileForm)
        .then((res) => {
          this.attachments.push(res.data.md5);
        })
        .catch((err) => console.log("File Upload Error", err));
    },
    removeAttach(item) {
      this.attachments.splice(this.attachments.indexOf(item), 1);
    },
    handleClearProduct() {
      this.$emit("update:selectedProduct", []);
    },
  },
  watch: {
    dialog() {
      this.isShowStatus = false;
      this.myObj = this.obj;
      this.getOrderinfo();
      this.TotalPriceQty();

      // console.log("Myobj", this.myObj);

      if (this.dialog) {
        this.attachments = [];

        if (Array.isArray(this.myObj.attach)) {
          this.myObj.attach.forEach((p) => this.attachments.push(p.md5));
        }
      }
    },
    obj() {
      this.myObj.customer_id = this.obj.customer_id;
      this.getCustomer();
    },
    orderStatus() {
      this.$emit("saveForm", "status", this.myObj);
      this.getOrderinfo();
    },
  },
  mounted() {
    this.getCurrency();
  },
};
</script>